import React, { Suspense, lazy, createContext, useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Spinner from './components/Base/Spinner/Spinner';
import './App.css';

export const AppDataContext = createContext(null);

const MainWindow = lazy(() => import('./components/Containers/CMainWindow/CMainWindow'));

export default function App() {
  const [component, setComponent] = useState('ChildComponent1');
  const [user, setUser] = useState(null);
  const [debugMode, setDebugMode] = useState(process.env.REACT_APP_DEBUG_MODE === 'true' );
  const [cartItems, setCartItems] = useState([]);

  const showCode = true;

  useEffect(() => {
    
    if (showCode === true) {
      debugMode === true ? (console.log("MAIN DEBUG SWITCH : ON")) : (console.log("MAIN DEBUG SWITCH : OFF"));
    } 
    setComponent("ChildComponent1");
  }, []);

  const appData = {
    user,
    setUser,
    debugMode,
    setDebugMode,
    cartItems,
    setCartItems,
    component,
    setComponent
  };

  return (
    <>
      <AppDataContext.Provider value={appData}>
        <Router>
          <Suspense fallback={<Spinner />}>
            <Routes>
              <Route
                path="/"
                element={
                  <MainWindow
                    setComponent={() => setComponent("ChildComponent1")}
                  />
                }
              />
              <Route
                path="/comingSoon"
                element={
                  <MainWindow
                    setComponent={() => setComponent("ChildComponent3")}
                  />
                }
              />
              <Route
                path="/Test"
                element={
                  <MainWindow
                    setComponent={() => setComponent("ChildComponent5")}
                  />
                }
              />
              <Route
                path="/qr"
                element={
                  <MainWindow
                    setComponent={() => setComponent("ChildComponent7")}
                  />
                }
              />
              <Route
                path="/amazon_store"
                element={ <NaiStore/>}
              />
              <Route
                path="*"
                element={
                  <NotFound debugMode={debugMode} setComponent={setComponent} />
                }
              />
            </Routes>
          </Suspense>
        </Router>
      </AppDataContext.Provider>
    </>
  );
}

function NaiStore() {
  // 👇️ using window.location.href 👇️
  window.location.href = "https://holisticlifeheal.com/amazon_store/index.html";
  return null;
}

function NotFound({debugMode, setComponent}) {
  return <>
    { debugMode === true ? (console.log ( "Page [", window.location.pathname, "] not found. Redirecting to main.")) : null }
    <MainWindow setComponent={() => setComponent('ChildComponent1')} />
  </>;
}
